var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SUPPORTED_BEATMAP_TYPES } from "../../game/GameModes";
import getHash from "../utils/Hash";
import { taikoToClassic } from "./TaikoConverter";
const converters = new Map([[1, new Map([[0, taikoToClassic]])]]);
export default function convertBeatmap(beatmap, toGameMode) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const beatmapType = parseInt(beatmap.info.type ? beatmap.info.type : beatmap.set.info.type);
        if (SUPPORTED_BEATMAP_TYPES[toGameMode] &&
            SUPPORTED_BEATMAP_TYPES[toGameMode].primary.indexOf(beatmapType) > -1) {
            beatmap.hash = yield getHash(beatmap, toGameMode);
            return;
        }
        const fromGameMode = parseInt((_a = Object.entries(SUPPORTED_BEATMAP_TYPES).find(([, types]) => types.primary.includes(beatmapType))) === null || _a === void 0 ? void 0 : _a[0]);
        if (converters.has(fromGameMode)) {
            const converter = converters.get(fromGameMode).get(toGameMode);
            if (converter) {
                converter(beatmap);
                beatmap.hash = yield getHash(beatmap, toGameMode);
            }
            else {
                console.warn(`No converter found for ${fromGameMode} to ${toGameMode}`);
                beatmap.hash = yield getHash(beatmap, fromGameMode);
            }
        }
        else {
            console.warn(`No converter found for ${fromGameMode}`);
            beatmap.hash = yield getHash(beatmap, fromGameMode);
        }
    });
}
