var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default class LocalStorageScoreRepository {
    saveHighscore(score) {
        return __awaiter(this, void 0, void 0, function* () {
            localStorage.setItem(score.gameMode +
                "score" +
                (score.beatmap.id ? score.beatmap.id : score.beatmap.hash), score.highScore + "");
            localStorage.setItem(score.gameMode +
                "combo" +
                (score.beatmap.id ? score.beatmap.id : score.beatmap.hash), score.maxCombo + "");
        });
    }
    getHighscore(beatmap, gameMode) {
        return __awaiter(this, void 0, void 0, function* () {
            const score = localStorage.getItem(gameMode + "score" + (beatmap.id ? beatmap.id : beatmap.hash));
            let highScore = 0;
            if (score) {
                highScore = parseInt(score);
            }
            const combo = localStorage.getItem(gameMode + "combo" + (beatmap.id ? beatmap.id : beatmap.hash));
            let maxCombo = 0;
            if (combo) {
                maxCombo = parseInt(combo);
            }
            return {
                beatmap: beatmap,
                gameMode: gameMode,
                score: 0,
                highScore: highScore,
                combo: 0,
                maxCombo: maxCombo,
                accuracy: null,
                judgementCounts: null,
                data: null,
            };
        });
    }
}
