let i;
export default class SimpleInputProvider {
    constructor(inputs) {
        this.inputs = inputs;
        this.listeners = [];
    }
    addListener(listener) {
        this.listeners.push(listener);
    }
    notifyListeners(input) {
        i = 0;
        while (i < this.listeners.length) {
            this.listeners[i](input);
            i++;
        }
    }
    getInputs() {
        return this.inputs;
    }
}
