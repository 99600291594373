import Setting from "../models/Setting";
export default class LocalStorageSettingsRepository {
    insertOrUpdate(setting) {
        const storedSettings = localStorage.getItem("settings");
        let settings = {};
        if (storedSettings) {
            settings = JSON.parse(storedSettings);
        }
        settings[setting.key] = setting.value;
        localStorage.setItem("settings", JSON.stringify(settings));
    }
    get(key) {
        const storedSettings = localStorage.getItem("settings");
        if (storedSettings) {
            const settings = JSON.parse(storedSettings);
            return new Setting(key, settings[key]);
        }
        return new Setting(key, null);
    }
    getAll() {
        const storedSettings = localStorage.getItem("settings");
        if (storedSettings) {
            return Object.entries(JSON.parse(storedSettings)).map((entry) => new Setting(entry[0], entry[1]));
        }
        return [];
    }
}
