var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@stitches/core';
import { generateClassNames } from '@supabase/auth-ui-shared';
const buttonDefaultStyles = css({
    fontFamily: '$buttonFontFamily',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    borderRadius: '$borderRadiusButton',
    fontSize: '$baseButtonSize',
    padding: '$buttonPadding',
    cursor: 'pointer',
    borderWidth: '$buttonBorderWidth',
    borderStyle: 'solid',
    width: '100%',
    transitionPproperty: 'background-color',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '100ms',
    '&:disabled': {
        opacity: 0.7,
        cursor: 'unset',
    },
    variants: {
        color: {
            default: {
                backgroundColor: '$defaultButtonBackground',
                color: '$defaultButtonText',
                borderColor: '$defaultButtonBorder',
                '&:hover:not(:disabled)': {
                    backgroundColor: '$defaultButtonBackgroundHover',
                },
            },
            primary: {
                backgroundColor: '$brand',
                color: '$brandButtonText',
                borderColor: '$brandAccent',
                '&:hover:not(:disabled)': {
                    backgroundColor: '$brandAccent',
                },
            },
        },
    },
});
const Button = (_a) => {
    var _b;
    var { children, color = 'default', appearance, icon, loading = false } = _a, props = __rest(_a, ["children", "color", "appearance", "icon", "loading"]);
    const classNames = generateClassNames('button', buttonDefaultStyles({ color: color }), appearance);
    return (_jsxs("button", Object.assign({}, props, { style: (_b = appearance === null || appearance === void 0 ? void 0 : appearance.style) === null || _b === void 0 ? void 0 : _b.button, className: classNames.join(' '), disabled: loading }, { children: [icon, children] })));
};
export { Button };
