var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
let noteManager;
/**
 * Simple note manager that handles different types of notes
 * by delegating tasks to the appropriate note manager for the given note type
 */
export default class BaseNotesManager {
    constructor(noteManagers) {
        this.noteManagers = noteManagers;
        this.noteManagerArr = Array.from(noteManagers.values());
    }
    load(beatmap) {
        return __awaiter(this, void 0, void 0, function* () {
            for (noteManager of this.noteManagerArr) {
                yield noteManager.load(beatmap);
            }
        });
    }
    spawnNote(note, spawnOffset) {
        this.noteManagers.get(note.type).spawnNote(note, spawnOffset);
    }
    deactivateNote(note) {
        this.noteManagers.get(note.type).deactivateNote(note);
    }
    update(deltaTime) {
        for (noteManager of this.noteManagerArr) {
            noteManager.update(deltaTime);
        }
    }
    reset() {
        for (noteManager of this.noteManagerArr) {
            noteManager.reset();
        }
    }
}
