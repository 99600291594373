export default class KeyboardInputProvider {
    constructor(inputMap) {
        this.inputMap = inputMap;
        this.inputs = [];
        this.inputsMap = new Map();
        this.listeners = [];
        for (const [key, keyAction] of inputMap.entries()) {
            const input = { id: keyAction, value: 0 };
            this.inputs.push(input);
            this.inputsMap.set(key, input);
        }
    }
    addListener(listener) {
        this.listeners.push(listener);
    }
    init(eventTarget) {
        eventTarget.addEventListener("keydown", (e) => {
            const input = this.inputsMap.get(e.key);
            if (input) {
                input.value = 1;
                for (const listener of this.listeners) {
                    listener(input);
                }
            }
        });
        eventTarget.addEventListener("keyup", (e) => {
            const input = this.inputsMap.get(e.key);
            if (input) {
                input.value = 0;
                for (const listener of this.listeners) {
                    listener(input);
                }
            }
        });
    }
    getInputs() {
        return this.inputs;
    }
}
