import { DynamicDrawUsage, InstancedMesh, Matrix4, Vector3, } from "three";
const dummyMatrix = new Matrix4();
const dummyVector = new Vector3();
export default class InstancedMeshObjectPool {
    constructor(geometry, material, count, defaultPosition) {
        this.mesh = new InstancedMesh(geometry, material, count);
        this.mesh.instanceMatrix.setUsage(DynamicDrawUsage);
        this.defaultPosition = defaultPosition;
        this.pool = [];
        for (let i = 0; i < count; i++) {
            this.releaseInstance(i);
        }
    }
    getInstance() {
        return this.pool.pop();
    }
    releaseInstance(instance) {
        dummyMatrix.identity();
        dummyMatrix.setPosition(this.defaultPosition);
        this.mesh.setMatrixAt(instance, dummyMatrix);
        this.pool.push(instance);
    }
    setInstanceColor(instance, color) {
        this.mesh.setColorAt(instance, color);
    }
    setInstancePositionFromVector3(instance, vector) {
        this.mesh.getMatrixAt(instance, dummyMatrix);
        dummyMatrix.setPosition(vector);
        this.mesh.setMatrixAt(instance, dummyMatrix);
    }
    setInstanceScaleX(instance, x) {
        this.mesh.getMatrixAt(instance, dummyMatrix);
        dummyVector.setFromMatrixScale(dummyMatrix);
        dummyVector.set(x / dummyVector.x, 1, 1);
        this.mesh.setMatrixAt(instance, dummyMatrix);
    }
    setInstanceScaleY(instance, y) {
        this.mesh.getMatrixAt(instance, dummyMatrix);
        dummyVector.setFromMatrixScale(dummyMatrix);
        dummyVector.set(1, y / dummyVector.y, 1);
        this.mesh.setMatrixAt(instance, dummyMatrix);
    }
    setInstanceScaleZ(instance, z) {
        this.mesh.getMatrixAt(instance, dummyMatrix);
        dummyVector.setFromMatrixScale(dummyMatrix);
        dummyVector.set(1, 1, z / dummyVector.z);
        this.mesh.setMatrixAt(instance, dummyMatrix);
    }
    setInstanceScaleXZ(instance, x, z) {
        this.mesh.getMatrixAt(instance, dummyMatrix);
        dummyVector.setFromMatrixScale(dummyMatrix);
        dummyVector.set(x / dummyVector.x, 1, z / dummyVector.z);
        this.mesh.setMatrixAt(instance, dummyMatrix);
    }
    setInstanceScale(instance, x, y, z) {
        this.mesh.getMatrixAt(instance, dummyMatrix);
        dummyVector.setFromMatrixScale(dummyMatrix);
        dummyVector.set(x / dummyVector.x, y / dummyVector.y, z / dummyVector.z);
        this.mesh.setMatrixAt(instance, dummyMatrix);
    }
    setInstancePosition(instance, x, y, z) {
        this.mesh.getMatrixAt(instance, dummyMatrix);
        dummyMatrix.setPosition(x, y, z);
        this.mesh.setMatrixAt(instance, dummyMatrix);
    }
    incrementInstancePositionZ(instance, z) {
        this.mesh.getMatrixAt(instance, dummyMatrix);
        dummyVector.setFromMatrixPosition(dummyMatrix);
        dummyVector.z += z;
        dummyMatrix.setPosition(dummyVector);
        this.mesh.setMatrixAt(instance, dummyMatrix);
    }
    requireUpdate() {
        this.mesh.instanceMatrix.needsUpdate = true;
    }
}
