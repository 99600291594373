import { Vector3 } from "three";
export const COLOR_BAD_MISS = new Vector3(1, 0, 0);
export const COLOR_GOOD = new Vector3(1, 0.8, 0);
export const COLOR_EXCELLENT = new Vector3(0, 1, 0);
export function getColor(judgement) {
    switch (judgement) {
        case 0 /* Judgement.MISS */:
        case 1 /* Judgement.BAD */:
            return COLOR_BAD_MISS;
        case 3 /* Judgement.GOOD */:
            return COLOR_GOOD;
        case 7 /* Judgement.EXCELLENT */:
            return COLOR_EXCELLENT;
        default:
            return null;
    }
}
