var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Container, Input, Label, Message } from './../../UI';
function UpdatePassword({ supabaseClient, i18n, appearance, }) {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const handlePasswordReset = (e) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        e.preventDefault();
        setError('');
        setMessage('');
        setLoading(true);
        const { error } = yield supabaseClient.auth.updateUser({ password });
        if (error)
            setError(error.message);
        else
            setMessage((_a = i18n === null || i18n === void 0 ? void 0 : i18n.update_password) === null || _a === void 0 ? void 0 : _a.confirmation_text);
        setLoading(false);
    });
    const labels = i18n === null || i18n === void 0 ? void 0 : i18n.update_password;
    return (_jsx("form", Object.assign({ id: "auth-update-password", onSubmit: handlePasswordReset }, { children: _jsxs(Container, Object.assign({ gap: "large", direction: 'vertical', appearance: appearance }, { children: [_jsxs(Container, Object.assign({ gap: "large", direction: "vertical", appearance: appearance }, { children: [_jsxs("div", { children: [_jsx(Label, Object.assign({ htmlFor: "password", appearance: appearance }, { children: labels === null || labels === void 0 ? void 0 : labels.password_label })), _jsx(Input, { id: "password", name: "password", placeholder: labels === null || labels === void 0 ? void 0 : labels.password_label, type: "password", autoFocus: true, onChange: (e) => setPassword(e.target.value), appearance: appearance })] }), _jsx(Button, Object.assign({ type: "submit", color: "primary", loading: loading, appearance: appearance }, { children: loading ? labels === null || labels === void 0 ? void 0 : labels.loading_button_label : labels === null || labels === void 0 ? void 0 : labels.button_label }))] })), message && _jsx(Message, Object.assign({ appearance: appearance }, { children: message })), error && (_jsx(Message, Object.assign({ color: "danger", appearance: appearance }, { children: error })))] })) })));
}
export { UpdatePassword };
