import PooledNoteManager from "./PooledNoteManager";
export default class ArrayPooledNoteManager extends PooledNoteManager {
    constructor(pool) {
        super();
        this.pool = pool;
    }
    getInstanceFromPool() {
        return this.pool.pop();
    }
    releaseInstanceToPool(instance) {
        this.pool.push(instance);
    }
}
