import { Box3 } from "three";
const EMPTY_FUNCTION = function () {
    return;
};
export default class Collider {
    constructor(groupId, isStatic, layer = 0) {
        this.collisionShapes = [];
        this.groupId = groupId;
        this.isStatic = isStatic;
        this.boundingBox = new Box3();
        this.collisions = new Map();
        this.layer = layer;
        this.layerMask = 1 << layer;
        this.layerCollisionMask = 0;
        this.onCollisionEnter = EMPTY_FUNCTION;
        this.onCollisionExit = EMPTY_FUNCTION;
    }
    collisionStart(id, other) {
        this.collisions.set(id, other);
        this.onCollisionEnter(other);
    }
    collisionEnd(id) {
        this.onCollisionExit(this.collisions.get(id));
        this.collisions.delete(id);
    }
    addCollisionShape(shape) {
        this.collisionShapes.push(shape);
    }
    getBoundingBox() {
        return this.boundingBox;
    }
}
