import { GAMEMODE_TAIKO } from "./GameModes";
import { GameStatus } from "./GameState";
const JUDGEMENT_THRESHOLD = 500;
const LARGE_HIT_THRESHOLD = 30;
const DON = "don";
const KAT = "kat";
const SCOREBIT = 0b00000001;
const DRUMROLLMASK = 0b1100;
const KATMASK = 0b0001;
export default class TaikoGameState {
    constructor() {
        this.events = [];
        this.listeners = new Map();
        this.noteQueue = []; //head of queue is at the end of the array
        this.eventPool = [
            { note: null, judgement: null },
            { note: null, judgement: null },
            { note: null, judgement: null },
            { note: null, judgement: null },
            { note: null, judgement: null },
            { note: null, judgement: null },
            { note: null, judgement: null },
            { note: null, judgement: null },
        ];
        this.shiftCounts = [0, 0, 0, 0, 0, 0, 0, 0];
        this.status = GameStatus.MENU;
        this.beatmap = null;
        this.currentSongTime = 0;
        this.timingOffset = 0;
        this.score = {
            beatmap: null,
            gameMode: GAMEMODE_TAIKO,
            score: 0,
            highScore: 0,
            combo: 0,
            maxCombo: 0,
            data: [],
        };
    }
    getGameMode() {
        return GAMEMODE_TAIKO;
    }
    addChangeListener(property, handler) {
        if (!this.listeners.has(property)) {
            this.listeners.set(property, []);
        }
        this.listeners.get(property).push(handler);
    }
    loadBeatmap(beatmap) {
        this.beatmap = beatmap;
        // assume beatmap notes are sorted by time in ascending order
        for (let i = this.beatmap.notes.length - 1; i > -1; i--) {
            this.noteQueue.push(this.beatmap.notes[i]);
        }
    }
    pushHitEvent(note, judgement, ignoreTimeDelta = false) {
        const event = this.eventPool.pop();
        event.judgement = judgement;
        event.note = note;
        event.ignoreTimeDelta = ignoreTimeDelta;
        this.events.push(event);
    }
    judgeHit(note) {
        if (note.absTimeDelta > 120 /* ErrorThresholds.GOOD */) {
            if (note.absTimeDelta < 180 /* ErrorThresholds.BAD */) {
                return 0 /* Judgement.MISS */;
            }
            else {
                return 1 /* Judgement.BAD */;
            }
        }
        else {
            if (note.absTimeDelta <= 45 /* ErrorThresholds.EXCELLENT */) {
                return 7 /* Judgement.EXCELLENT */;
            }
            else {
                return 3 /* Judgement.GOOD */;
            }
        }
    }
    updateTimeDelta(note) {
        if (note.isActive) {
            note.timeDelta = note.endTime
                ? this.currentSongTime - note.endTime
                : this.currentSongTime - note.startTime;
        }
        else {
            note.timeDelta = this.currentSongTime - note.startTime;
        }
    }
    reset() {
        this.score.score = 0;
        this.score.combo = 0;
        this.score.accuracy = 0;
        this.score.data.length = 0;
        this.score.judgementCounts = {};
        this.shiftCounts.fill(0);
        this.noteQueue.length = 0;
        while (this.events.length > 0) {
            this.eventPool.push(this.events.pop());
        }
        for (const note of this.beatmap.notes) {
            note.isActive = false;
            if (note.type === 2 /* NoteTypes.LARGEDON */ ||
                note.type === 3 /* NoteTypes.LARGEKAT */) {
                delete note.activationTime;
            }
        }
    }
    setStatus(status) {
        this.status = status;
        for (const handler of this.listeners.get("status")) {
            handler(status);
        }
    }
    updateScore() {
        if (this.events.length > 0) {
            for (const event of this.events) {
                if (event.judgement === 0 /* Judgement.MISS */) {
                    this.score.combo = 0;
                    this.score.data.push(event.note.timeDelta);
                }
                else {
                    if (event.judgement & SCOREBIT) {
                        this.score.combo += 1;
                        if (this.score.combo > this.score.maxCombo) {
                            this.score.maxCombo = this.score.combo;
                        }
                        switch (event.judgement) {
                            case 1 /* Judgement.BAD */:
                                this.score.score += 1;
                                break;
                            case 3 /* Judgement.GOOD */:
                                this.score.score += 2;
                                break;
                            case 7 /* Judgement.EXCELLENT */:
                                this.score.score += 6;
                                break;
                        }
                        if (this.score.score > this.score.highScore) {
                            this.score.highScore = this.score.score;
                        }
                    }
                    if (!event.ignoreTimeDelta) {
                        this.score.data.push(event.note.timeDelta);
                    }
                }
            }
            for (const handler of this.listeners.get("score")) {
                handler(this.score);
            }
        }
    }
    update(newSongTime, inputs) {
        this.currentSongTime = newSongTime + this.timingOffset;
        // Latency sources:
        // 1. Song audio latency: time between song time and actual time user hears sound
        // 2. Input feedback latency: time between user input and when user feels haptic/hears audio
        // If input feedback latency is greater than song audio latency. User should trigger the input earlier to compensate.
        if (this.status === GameStatus.PLAYING) {
            while (this.events.length > 0) {
                this.eventPool.push(this.events.pop());
            }
            // handle notes at front of each queue
            let note = this.noteQueue[this.noteQueue.length - 1];
            if (note) {
                this.updateTimeDelta(note);
                if (note.timeDelta > 180 /* ErrorThresholds.BAD */) {
                    if (note.isActive) {
                        note.isActive = false;
                        this.pushHitEvent(note, 2 /* Judgement.PASS */);
                    }
                    else {
                        this.pushHitEvent(note, 0 /* Judgement.MISS */);
                    }
                    this.noteQueue.pop();
                    note = this.noteQueue[this.noteQueue.length - 1];
                }
                else if (note.isActive) {
                    if (note.type & DRUMROLLMASK) {
                        if (note.timeDelta > 0) {
                            note.isActive = false;
                            this.pushHitEvent(note, 2 /* Judgement.PASS */);
                            this.noteQueue.pop();
                            note = this.noteQueue[this.noteQueue.length - 1];
                        }
                    }
                    else if (this.currentSongTime - note.activationTime >
                        LARGE_HIT_THRESHOLD) {
                        note.isActive = false;
                        this.pushHitEvent(note, 2 /* Judgement.PASS */);
                        this.noteQueue.pop();
                        note = this.noteQueue[this.noteQueue.length - 1];
                    }
                }
            }
            const don = inputs.eventMap.get(DON);
            const kat = inputs.eventMap.get(KAT);
            if ((don || kat) && note) {
                note.absTimeDelta = Math.abs(note.timeDelta);
                if (note.isActive) {
                    if (note.type & DRUMROLLMASK) {
                        this.pushHitEvent(note, 7 /* Judgement.EXCELLENT */, true);
                        //TODO handle small vs large vs shaker
                    }
                    else {
                        // large don/kat
                        if (note.type === 3 /* NoteTypes.LARGEKAT */) {
                            if (don) {
                                this.pushHitEvent(note, 0 /* Judgement.MISS */);
                            }
                            else {
                                this.pushHitEvent(note, this.judgeHit(note));
                            }
                        }
                        else {
                            // don
                            if (kat) {
                                this.pushHitEvent(note, 0 /* Judgement.MISS */);
                            }
                            else {
                                this.pushHitEvent(note, this.judgeHit(note));
                            }
                        }
                        note.isActive = false;
                        this.noteQueue.pop();
                    }
                }
                else if (note.absTimeDelta <= JUDGEMENT_THRESHOLD) {
                    if (note.type & DRUMROLLMASK) {
                        this.pushHitEvent(note, 7 /* Judgement.EXCELLENT */);
                        note.isActive = true;
                        //TODO handle small vs large vs shaker
                    }
                    else {
                        if (note.type & KATMASK) {
                            if (don) {
                                this.pushHitEvent(note, 0 /* Judgement.MISS */);
                                this.noteQueue.pop();
                            }
                            else {
                                this.pushHitEvent(note, this.judgeHit(note));
                                if (note.type === 3 /* NoteTypes.LARGEKAT */) {
                                    if ((kat & 0b11) === 0b11) {
                                        // double kat hit
                                        this.pushHitEvent(note, this.judgeHit(note));
                                        this.noteQueue.pop();
                                    }
                                    else {
                                        note.isActive = true;
                                        note.activationTime = this.currentSongTime;
                                    }
                                }
                                else {
                                    this.noteQueue.pop();
                                }
                            }
                        }
                        else {
                            // don
                            if (kat) {
                                this.pushHitEvent(note, 0 /* Judgement.MISS */);
                                this.noteQueue.pop();
                            }
                            else {
                                this.pushHitEvent(note, this.judgeHit(note));
                                if (note.type === 2 /* NoteTypes.LARGEDON */) {
                                    if ((don & 0b11) === 0b11) {
                                        // double don hit
                                        this.pushHitEvent(note, this.judgeHit(note));
                                        this.noteQueue.pop();
                                    }
                                    else {
                                        note.isActive = true;
                                        note.activationTime = this.currentSongTime;
                                    }
                                }
                                else {
                                    this.noteQueue.pop();
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
