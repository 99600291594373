import { Euler, Group, Vector3 } from "three";
import BaseNotesManager from "../../BaseNotesManager";
import InstancedClassicNoteManager from "./InstancedClassicNoteManager";
const BASE_Y = 1.595;
const BASE_Z = -4.29;
const RAIL_WIDTH = 0.15;
const NUM_RAILS = 8;
export default class ClassicNotesManager extends BaseNotesManager {
    constructor(railAngle, railLength, timeWindow) {
        super(new Map([
            [
                0 /* NoteTypes.HIT_NOTE */,
                new InstancedClassicNoteManager(0 /* NoteTypes.HIT_NOTE */, 100, railLength / timeWindow, new Vector3(0, 0, 1).applyEuler(new Euler(railAngle, 0, 0)), RAIL_WIDTH, railLength),
            ],
            [
                1 /* NoteTypes.SLIDE_NOTE */,
                new InstancedClassicNoteManager(1 /* NoteTypes.SLIDE_NOTE */, 100, railLength / timeWindow, new Vector3(0, 0, 1).applyEuler(new Euler(railAngle, 0, 0)), RAIL_WIDTH, railLength),
            ],
            [
                2 /* NoteTypes.HOLD_NOTE */,
                new InstancedClassicNoteManager(2 /* NoteTypes.HOLD_NOTE */, 20, railLength / timeWindow, new Vector3(0, 0, 1).applyEuler(new Euler(railAngle, 0, 0)), RAIL_WIDTH, railLength),
            ],
            [
                3 /* NoteTypes.ROLL_NOTE */,
                new InstancedClassicNoteManager(3 /* NoteTypes.ROLL_NOTE */, 20, railLength / timeWindow, new Vector3(0, 0, 1).applyEuler(new Euler(railAngle, 0, 0)), RAIL_WIDTH, railLength),
            ],
        ]));
        this.railAngle = railAngle;
        this.railLength = railLength;
        this.timeWindow = timeWindow;
        this.railWidth = RAIL_WIDTH;
        this.numRails = NUM_RAILS;
    }
    init(parent) {
        this.noteContainer = new Group();
        parent.add(this.noteContainer);
        this.noteContainer.position.x =
            (this.numRails / 2 - 0.5) * this.railWidth * -1;
        this.noteContainer.position.y =
            BASE_Y + (this.railLength / 2) * Math.sin(this.railAngle);
        this.noteContainer.position.z =
            BASE_Z - (this.railLength / 2) * Math.cos(this.railAngle);
        for (const noteManager of this.noteManagerArr) {
            noteManager.init(this.noteContainer);
        }
    }
    updateHeight(height) {
        this.noteContainer.position.y =
            BASE_Y + (this.railLength / 2) * Math.sin(this.railAngle) + height / 100;
    }
}
