import { Euler, Group, Vector3 } from "three";
import BaseNotesManager from "../../BaseNotesManager";
import DonKatNotesManager from "./DonKatNotesManager";
import DrumrollNoteManager from "./DrumrollNotesManager";
const basePositionY = 1.57;
const basePositionZ = -4.29;
export default class TaikoNotesManager extends BaseNotesManager {
    constructor(railAngle, railLength, timeWindow, numSmallDon = 50, numSmallKat = 50, numLargeDon = 25, numLargeKat = 25, numSmallDrumroll = 5, numLargeDrumroll = 5) {
        super(new Map([
            [
                0 /* NoteTypes.SMALLDON */,
                new DonKatNotesManager(true, false, numSmallDon, new Vector3(0, basePositionY + (railLength / 2) * Math.sin(railAngle), basePositionZ - (railLength / 2) * Math.cos(railAngle)).applyAxisAngle(new Vector3(-1, 0, 0), railAngle), railLength / timeWindow, new Vector3(0, 0, 1).applyEuler(new Euler(railAngle, 0, 0))),
            ],
            [
                1 /* NoteTypes.SMALLKAT */,
                new DonKatNotesManager(false, false, numSmallKat, new Vector3(0, basePositionY + (railLength / 2) * Math.sin(railAngle), basePositionZ - (railLength / 2) * Math.cos(railAngle)).applyAxisAngle(new Vector3(-1, 0, 0), railAngle), railLength / timeWindow, new Vector3(0, 0, 1).applyEuler(new Euler(railAngle, 0, 0))),
            ],
            [
                2 /* NoteTypes.LARGEDON */,
                new DonKatNotesManager(true, true, numLargeDon, new Vector3(0, basePositionY + (railLength / 2) * Math.sin(railAngle), basePositionZ - (railLength / 2) * Math.cos(railAngle)).applyAxisAngle(new Vector3(-1, 0, 0), railAngle), railLength / timeWindow, new Vector3(0, 0, 1).applyEuler(new Euler(railAngle, 0, 0))),
            ],
            [
                3 /* NoteTypes.LARGEKAT */,
                new DonKatNotesManager(false, true, numLargeKat, new Vector3(0, basePositionY + (railLength / 2) * Math.sin(railAngle), basePositionZ - (railLength / 2) * Math.cos(railAngle)).applyAxisAngle(new Vector3(-1, 0, 0), railAngle), railLength / timeWindow, new Vector3(0, 0, 1).applyEuler(new Euler(railAngle, 0, 0))),
            ],
            [
                4 /* NoteTypes.SMALLDRUMROLL */,
                new DrumrollNoteManager(false, numSmallDrumroll, new Vector3(0, basePositionY + (railLength / 2) * Math.sin(railAngle), basePositionZ - (railLength / 2) * Math.cos(railAngle)).applyAxisAngle(new Vector3(-1, 0, 0), railAngle), railLength / timeWindow, new Vector3(0, 0, 1).applyEuler(new Euler(railAngle, 0, 0))),
            ],
            [
                6 /* NoteTypes.LARGEDRUMROLL */,
                new DrumrollNoteManager(true, numLargeDrumroll, new Vector3(0, basePositionY + (railLength / 2) * Math.sin(railAngle), basePositionZ - (railLength / 2) * Math.cos(railAngle)).applyAxisAngle(new Vector3(-1, 0, 0), railAngle), railLength / timeWindow, new Vector3(0, 0, 1).applyEuler(new Euler(railAngle, 0, 0))),
            ],
        ]));
        this.railAngle = railAngle;
        this.railLength = railLength;
        this.timeWindow = timeWindow;
    }
    init(parent) {
        this.noteContainer = new Group();
        parent.add(this.noteContainer);
        for (const noteManager of this.noteManagerArr) {
            noteManager.init(this.noteContainer);
        }
    }
    updateHeight(height) {
        this.noteContainer.position.y = height / 100;
    }
}
