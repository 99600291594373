var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GameStatus } from "../game/GameState";
export default class BaseGraphicsManager {
    constructor(noteManager, timeWindow) {
        this.notesManager = noteManager;
        this.timeWindow = timeWindow;
        this.currentNote = 0;
    }
    loadBeatmap(beatmap) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.notesManager.load(beatmap);
        });
    }
    update(gamestate, deltaTime) {
        if (gamestate.status === GameStatus.PLAYING) {
            for (const hitEvent of gamestate.events) {
                const note = hitEvent.note;
                if (!note.isActive) {
                    this.notesManager.deactivateNote(note);
                }
            }
            this.notesManager.update(deltaTime);
            const offsetTime = gamestate.currentSongTime + this.timeWindow;
            const notes = gamestate.beatmap.notes;
            let note = notes[this.currentNote];
            while (note != null && note.startTime < offsetTime) {
                this.notesManager.spawnNote(note, offsetTime - note.startTime);
                note = notes[++this.currentNote];
            }
        }
    }
    onGameStart() {
        this.currentNote = 0;
    }
    onGameRestart() {
        this.currentNote = 0;
        this.notesManager.reset();
    }
    onReturnToMenu() {
        this.currentNote = 0;
        this.notesManager.reset();
    }
}
