require("aframe-haptics-component");
require("./geometries/roundedRect");
require("./components/collision-detection/collider");
require("./components/collision-detection/shape");
require("./components/ui/button");
require("./components/ui/checkbox");
require("./components/ui/spinner");
require("./components/ui/windowed-selector");
require("./components/ui/x-button");
require("./components/ui/ad-panel");
require("./components/clickable");
require("./components/detachable");
require("./components/game-camera");
require("./components/GameController");
require("./components/input-devices/keyboard");
require("./components/input-devices/drum");
require("./components/input-devices/microphone");
require("./components/input-devices/toneDisplay");
require("./components/input-devices/trombone");
require("./components/mallet");
require("./components/menu");
require("./components/noteEmitter");
require("./components/setting");
require("./components/bindSetting");
require("./components/starfield");
require("./components/detachable");
require("./components/leaderboard");
require("./systems/audio");
require("./systems/clickable");
require("./systems/collision-detection");
require("./systems/db");
require("./systems/input");
require("./systems/mallet");
require("./systems/scene-controller");
require("./systems/scores");
require("./systems/settings");
require("./systems/ui");
require("./login-modal");
