var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Supabase from "../../supabase";
import { getRank } from "../models/Score";
export default class CadenzaScoreRepository {
    constructor(localStorageRepository) {
        this.localStorageRepository = localStorageRepository;
        this.supabaseClient = Supabase.getInstance().client;
    }
    saveHighscore(score) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.localStorageRepository.saveHighscore(score);
            const session = yield this.supabaseClient.auth.getSession();
            if (session.data.session && score.score >= score.highScore) {
                const { data, error } = yield this.supabaseClient.from("scores").upsert({
                    map: score.beatmap.hash,
                    user: session.data.session.user.id,
                    mode: score.gameMode,
                    score: score.highScore,
                    acc: Math.round(parseFloat(score.accuracy.toFixed(2)) * 100),
                    ss: score.rank === "SS",
                    created_at: new Date().toISOString(),
                }, { onConflict: "map, user, mode" });
                if (error) {
                    console.error(error);
                }
            }
        });
    }
    syncHighscore(beatmap, gameMode) {
        return __awaiter(this, void 0, void 0, function* () {
            const highScore = yield this.localStorageRepository.getHighscore(beatmap, gameMode);
            const session = yield this.supabaseClient.auth.getSession();
            if (session.data.session) {
                yield fetch(`https://scores.cadenzavr.com/${beatmap.hash}/${gameMode}/${session.data.session.user.id}`)
                    .then((res) => res.json())
                    .then((data) => {
                    if (data.length > 0 && data[0].score > highScore.highScore) {
                        highScore.highScore = data[0].score;
                        highScore.highScoreAccuracy = data[0].acc / 100;
                        highScore.highScoreRank = data[0].ss
                            ? "SS"
                            : getRank(highScore.highScoreAccuracy, {});
                        this.localStorageRepository.saveHighscore(highScore);
                    }
                })
                    .catch((err) => {
                    console.error(err);
                });
            }
        });
    }
    getHighscore(beatmap, gameMode) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.localStorageRepository.getHighscore(beatmap, gameMode);
        });
    }
}
