var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Dexie from "dexie";
class CadenzaDatabase extends Dexie {
    constructor() {
        super("CadenzaBeatmaps");
        this.version(1).stores({
            beatmapInfos: "++id, mode, genre, language, *tags",
            beatmaps: "++id",
            songs: "++id",
        });
        this.version(2)
            .stores({
            beatmapInfos: "++id, mode, genre, language, *tags, format",
        })
            .upgrade((transaction) => {
            return transaction
                .table("beatmapInfos")
                .toCollection()
                .modify((info) => {
                info.format = "json";
                info.mode = "7";
            });
        });
    }
}
export default class IndexedDBBeatmapRepository {
    constructor() {
        this.db = new CadenzaDatabase();
    }
    fetchAndSaveData(src, table) {
        return __awaiter(this, void 0, void 0, function* () {
            const blob = yield fetch(src).then((res) => res.blob());
            return table.add({
                data: blob,
            });
        });
    }
    saveBeatmapSet(beatmapSet) {
        return __awaiter(this, void 0, void 0, function* () {
            beatmapSet.beatmaps.sort((a, b) => a.info.difficulty - b.info.difficulty);
            const imageSrc = beatmapSet.info.imageSrc;
            let imageBlob;
            if (imageSrc.startsWith("#")) {
                imageBlob = yield fetch(document.getElementById(imageSrc.substring(1)).src).then((res) => res.blob());
            }
            else {
                imageBlob = yield fetch(imageSrc).then((res) => res.blob());
            }
            const beatmapSetInfo = {
                name: beatmapSet.info.song,
                artist: beatmapSet.info.artist,
                creator: beatmapSet.info.creator,
                image: imageBlob,
                mode: beatmapSet.info.type,
                genre: beatmapSet.info.genre,
                language: beatmapSet.info.language,
                tags: beatmapSet.info.tags,
                format: beatmapSet.info.srcFormat,
                difficulties: beatmapSet.beatmaps.map((beatmap) => beatmap.info.difficulty),
                difficultyNames: beatmapSet.beatmaps.map((beatmap) => beatmap.info.name),
            };
            if (beatmapSet.info.src) {
                beatmapSetInfo["src"] = yield this.fetchAndSaveData(beatmapSet.info.src, this.db.beatmaps);
            }
            else {
                const beatmapIds = [];
                for (const beatmap of beatmapSet.beatmaps) {
                    beatmapIds.push(yield this.fetchAndSaveData(beatmap.info.src, this.db.beatmaps));
                }
                beatmapSetInfo["beatmapIds"] = beatmapIds;
                beatmapSetInfo["songId"] = yield this.fetchAndSaveData(beatmapSet.info.audioSrc, this.db.songs);
            }
            yield this.db.beatmapInfos.put(beatmapSetInfo);
        });
    }
    getBeatmapSets() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.db.beatmapInfos.toArray().then((infos) => infos.map((info) => {
                const beatmapSet = {
                    id: info.id + "",
                    info: {
                        song: info.name,
                        artist: info.artist,
                        creator: info.creator,
                        imageSrc: URL.createObjectURL(info.image),
                        audioSrc: info.songId,
                        type: info.mode,
                        genre: info.genre,
                        language: info.language,
                        tags: info.tags,
                        srcFormat: info.format,
                    },
                    beatmaps: [],
                };
                for (let i = 0; i < info.difficulties.length; i++) {
                    let id;
                    if (info.beatmapIds) {
                        id = info.beatmapIds[i] + "";
                    }
                    else {
                        id = info.src + "-" + i;
                    }
                    beatmapSet.beatmaps.push({
                        id: id,
                        info: {
                            name: info.difficultyNames[i],
                            difficulty: info.difficulties[i],
                            src: info.beatmapIds ? info.beatmapIds[i] : null,
                        },
                        notes: null,
                        set: beatmapSet,
                    });
                }
                if (info.src) {
                    beatmapSet.info.src = info.src;
                }
                return beatmapSet;
            }));
        });
    }
    getBeatmap(id) {
        return this.db.beatmaps.get(id);
    }
    getSong(id) {
        return this.db.songs.get(id);
    }
}
