import { createClient } from "@supabase/supabase-js";
export default class Supabase {
    constructor() {
        this.client = createClient('https://lulumscaujqidtihbecm.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx1bHVtc2NhdWpxaWR0aWhiZWNtIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njc3ODExMjYsImV4cCI6MTk4MzM1NzEyNn0.JsgDvuKPXtgpCOiKyvxtgwr7ZUOq-rLrk-eQReOX2Lk');
    }
    static getInstance() {
        if (!Supabase.instance) {
            Supabase.instance = new Supabase();
        }
        return Supabase.instance;
    }
}
