let instance;
/**
 * Basic single note type manager
 */
export default class PooledNoteManager {
    constructor() {
        this.noteInstanceMap = new Map();
        this.activeNotes = new Set();
    }
    spawnNote(note, spawnOffsetTime) {
        instance = this.getInstanceFromPool();
        this.spawnInstance(note, instance, spawnOffsetTime);
        this.noteInstanceMap.set(note, instance);
        this.activeNotes.add(instance);
    }
    deactivateNote(note) {
        instance = this.noteInstanceMap.get(note);
        if (this.activeNotes.delete(instance)) {
            this.releaseInstanceToPool(instance);
        }
    }
    update(deltaTime) {
        for (instance of this.activeNotes) {
            this.updateInstance(instance, deltaTime);
        }
    }
    reset() {
        for (instance of this.activeNotes) {
            this.releaseInstanceToPool(instance);
        }
        this.activeNotes.clear();
        this.noteInstanceMap.clear();
    }
}
