import { Euler, Group, Vector3 } from "three";
import BaseNotesManager from "../../BaseNotesManager";
import InstancedTonoNoteManager from "./InstancedTonoNoteManager";
const BASE_Y = 1.595;
const BASE_Z = -4.29;
const RAIL_WIDTH = 2.4;
export default class TonoNotesManager extends BaseNotesManager {
    constructor(railAngle, railLength, timeWindow) {
        super(new Map([
            [
                0,
                new InstancedTonoNoteManager(100, railLength / timeWindow, new Vector3(0, 0, 1).applyEuler(new Euler(railAngle, 0, 0)), RAIL_WIDTH, railLength),
            ],
        ]));
        this.railAngle = railAngle;
        this.railLength = railLength;
        this.timeWindow = timeWindow;
        this.railWidth = RAIL_WIDTH;
    }
    spawnNote(note, spawnOffset) {
        this.noteManagerArr[0].spawnNote(note, spawnOffset);
    }
    deactivateNote(note) {
        this.noteManagerArr[0].deactivateNote(note);
    }
    init(parent) {
        this.noteContainer = new Group();
        parent.add(this.noteContainer);
        this.noteContainer.position.x = 0;
        this.noteContainer.position.y =
            BASE_Y + (this.railLength / 2) * Math.sin(this.railAngle);
        this.noteContainer.position.z =
            BASE_Z - (this.railLength / 2) * Math.cos(this.railAngle);
        for (const noteManager of this.noteManagerArr) {
            noteManager.init(this.noteContainer);
        }
    }
    updateHeight(height) {
        this.noteContainer.position.y =
            BASE_Y + (this.railLength / 2) * Math.sin(this.railAngle) + height / 100;
    }
}
