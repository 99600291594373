import PooledNoteManager from "../PooledNoteManager";
export default class InstancedMeshNoteManager extends PooledNoteManager {
    constructor(pool) {
        super();
        this.pool = pool;
    }
    getInstanceFromPool() {
        return this.pool.getInstance();
    }
    releaseInstanceToPool(instance) {
        this.pool.releaseInstance(instance);
    }
    reset() {
        super.reset();
        this.pool.requireUpdate();
    }
    spawnInstance(note, instance, spawnOffsetTime) {
        this.pool.setInstancePositionFromVector3(instance, this.getOffsetSpawnPosition(note, spawnOffsetTime));
    }
    update(deltaTime) {
        super.update(deltaTime);
        this.pool.mesh.instanceMatrix.needsUpdate = true;
    }
}
