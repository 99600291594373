import { Box3, Line3, Matrix4, Quaternion, Vector3, } from "three";
export const SHAPE_TYPE_AABB3 = 0;
export const SHAPE_TYPE_SPHERE = 1;
export const SHAPE_TYPE_AARECT = 2;
export const SHAPE_TYPE_CIRCLE = 3;
export const SHAPE_TYPE_CAPSULE = 4;
export const COLLISION_MATRIX = [
    [null, AABB3Sphere, null, null, null],
    [sphereAABB3, sphereSphere, sphereAARect, sphereCircle, sphereCapsule],
    [null, AARectSphere, null, null, null],
    [null, circleSphere, null, null, null],
    [null, capsuleSphere, null, null, null],
];
export const getMatrix = (params) => {
    if (params.matrix) {
        return params.matrix.clone();
    }
    const matrix = new Matrix4();
    matrix.compose(params.position || new Vector3(), params.rotation || new Quaternion(), params.scale || new Vector3(1, 1, 1));
    return matrix;
};
export const createAABB3 = (boundingBox, parentTransform = {}, localTransform = {}) => {
    return {
        type: SHAPE_TYPE_AABB3,
        boundingBox,
    };
};
export const createSphere = (boundingSphere, parentTransform = {}, localTransform = {}) => {
    return {
        type: SHAPE_TYPE_SPHERE,
        boundingSphere,
        boundingBox: boundingSphere.getBoundingBox(new Box3()),
        updateParentTransform: function (parentMatrix) {
            this.boundingSphere.center.setFromMatrixPosition(parentMatrix);
            this.boundingSphere.getBoundingBox(this.boundingBox);
        },
    };
};
export const createAARect = (boundingBox, plane, parentTransform = {}, localTransform = {}) => {
    return {
        type: SHAPE_TYPE_AARECT,
        boundingBox,
        plane,
    };
};
export const createCircle = (boundingSphere, plane, parentTransform = {}, localTransform = {}) => {
    return {
        type: SHAPE_TYPE_CIRCLE,
        boundingSphere,
        plane,
        boundingBox: boundingSphere.getBoundingBox(new Box3()),
    };
};
export const createCapsule = (height, radius, parentTransform = {}, localTransform = {}) => {
    const localMatrix = getMatrix(localTransform);
    const worldMatrix = localMatrix.clone();
    worldMatrix.premultiply(getMatrix(parentTransform));
    const baseBoundingBox = new Box3().setFromCenterAndSize(new Vector3(0, 0, 0), new Vector3(radius * 2, height + radius * 2, radius * 2));
    const boundingBox = baseBoundingBox.clone();
    boundingBox.applyMatrix4(worldMatrix);
    const baseLine = new Line3(new Vector3(0, height / 2, 0), new Vector3(0, -height / 2, 0));
    const line = baseLine.clone();
    line.applyMatrix4(worldMatrix);
    return {
        type: SHAPE_TYPE_CAPSULE,
        baseLine,
        line,
        radius,
        baseRadius: radius,
        boundingBox,
        baseBoundingBox,
        matrix: localMatrix,
        matrixWorld: worldMatrix,
        updateParentTransform: function (parentMatrix) {
            this.matrixWorld.copy(this.matrix);
            this.matrixWorld.premultiply(parentMatrix);
            this.line.copy(this.baseLine);
            this.line.applyMatrix4(this.matrixWorld);
            this.boundingBox.copy(this.baseBoundingBox);
            this.boundingBox.applyMatrix4(this.matrixWorld);
        },
        updateParentScale: function (parentScale) {
            this.radius =
                (this.baseRadius * (parentScale.x + parentScale.y + parentScale.z)) / 3;
        },
    };
};
// Collision detection functions
export function sphereAABB3(sphere, box) {
    return box.boundingBox.intersectsSphere(sphere.boundingSphere);
}
export function AABB3Sphere(box, sphere) {
    return box.boundingBox.intersectsSphere(sphere.boundingSphere);
}
export function sphereAARect(sphere, rect) {
    return (sphere.boundingSphere.intersectsPlane(rect.plane) &&
        rect.boundingBox.intersectsSphere(sphere.boundingSphere));
}
export function AARectSphere(rect, sphere) {
    return (sphere.boundingSphere.intersectsPlane(rect.plane) &&
        rect.boundingBox.intersectsSphere(sphere.boundingSphere));
}
export function sphereCircle(sphere, circle) {
    return (sphere.boundingSphere.intersectsPlane(circle.plane) &&
        circle.boundingSphere.intersectsSphere(sphere.boundingSphere));
}
export function circleSphere(circle, sphere) {
    return (sphere.boundingSphere.intersectsPlane(circle.plane) &&
        circle.boundingSphere.intersectsSphere(sphere.boundingSphere));
}
export function sphereSphere(sphere1, sphere2) {
    return sphere1.boundingSphere.intersectsSphere(sphere2.boundingSphere);
}
const ab = new Vector3();
const ac = new Vector3();
const bc = new Vector3();
let e;
let f;
// Adapted from Real-Time Collision Detection by Christer Ericson
function sqDistanceToPoint(line, point) {
    ab.subVectors(line.end, line.start);
    ac.subVectors(point, line.start);
    e = ac.dot(ab);
    if (e <= 0) {
        return ac.lengthSq();
    }
    f = ab.lengthSq();
    if (e >= f) {
        return bc.subVectors(point, line.end).lengthSq();
    }
    return ac.lengthSq() - (e * e) / f;
}
export function sphereCapsule(sphere, capsule) {
    e = sqDistanceToPoint(capsule.line, sphere.boundingSphere.center);
    f = sphere.boundingSphere.radius + capsule.radius;
    return e <= f * f;
}
export function capsuleSphere(capsule, sphere) {
    e = sqDistanceToPoint(capsule.line, sphere.boundingSphere.center);
    f = sphere.boundingSphere.radius + capsule.radius;
    return e <= f * f;
}
export function intersects(shape1, shape2) {
    return COLLISION_MATRIX[shape1.type][shape2.type](shape1, shape2);
}
