import Setting from "./models/Setting";
export default class SettingsManager {
    constructor(settingsRepository) {
        this.settingsRepository = settingsRepository;
        this.observers = new Map();
    }
    addObserver(settingKey, observer) {
        if (!this.observers.has(settingKey)) {
            this.observers.set(settingKey, []);
        }
        this.observers.get(settingKey).push(observer);
    }
    updateSetting(key, value) {
        this.settingsRepository.insertOrUpdate(new Setting(key, value));
        if (this.observers.has(key)) {
            for (const observer of this.observers.get(key)) {
                observer(value);
            }
        }
    }
    getSettingValue(key) {
        return this.settingsRepository.get(key).value;
    }
}
