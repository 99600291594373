import { Vector3, Vector4, Matrix4 } from "three";
import { createClampedVisibiltyMaterial } from "../../../objects/note";
import { flatRectGeometry } from "../../../utils/geometryUtils";
import InstancedMeshObjectPool from "../InstancedMeshObjectPool";
import InstancedSimpleNoteManager from "../InstancedSimpleNoteManager";
const COLOR_CYAN = new Vector4(0, 1, 1, 1);
const COLOR_YELLOW = new Vector4(1, 1, 0, 1);
const BASE_POSITION = new Vector3(0, 0.0001, 10);
const BASE_NOTE_WIDTH = 0.12;
const BASE_NOTE_HEIGHT = 0.05;
const dummyMatrix = new Matrix4();
const dummyVector = new Vector3();
export default class InstancedClassicNoteManager extends InstancedSimpleNoteManager {
    constructor(type, numInstances, moveSpeed, moveDirection, railWidth, railLength) {
        super(new InstancedMeshObjectPool(flatRectGeometry(BASE_NOTE_WIDTH, BASE_NOTE_HEIGHT), createClampedVisibiltyMaterial({
            isInstanced: true,
            minZ: 0,
            maxZ: railLength,
        }), numInstances, BASE_POSITION), null, moveSpeed, moveDirection);
        if (type === 1 /* NoteTypes.SLIDE_NOTE */) {
            this.pool.mesh.material.uniforms.color.value =
                COLOR_YELLOW;
        }
        else if (type === 3 /* NoteTypes.ROLL_NOTE */) {
            this.pool.mesh.material.uniforms.color.value =
                COLOR_CYAN;
        }
        this.baseNoteHeight = BASE_NOTE_HEIGHT;
        this.baseNoteWidth = BASE_NOTE_WIDTH;
        this.railWidth = railWidth;
        this.railLength = railLength;
    }
    spawnInstance(note, instance, spawnOffsetTime) {
        const targetHeight = this.baseNoteHeight + this.moveSpeed * note.duration;
        dummyMatrix.identity();
        dummyVector.x = note.key * this.railWidth;
        dummyVector.y = 0;
        dummyVector.z = 0;
        dummyVector.z +=
            spawnOffsetTime * this.moveSpeed +
                (this.baseNoteHeight - targetHeight) / 2;
        dummyVector.x += ((note.width - 1) * this.railWidth) / 2;
        dummyMatrix.setPosition(dummyVector);
        dummyVector.set((this.railWidth * note.width - 0.02) / this.baseNoteWidth, 1, targetHeight / this.baseNoteHeight);
        dummyMatrix.scale(dummyVector);
        this.pool.mesh.setMatrixAt(instance, dummyMatrix);
    }
}
