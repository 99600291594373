var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function deserializeJsonBeatmap(blob, beatmap) {
    return __awaiter(this, void 0, void 0, function* () {
        const beatmapObj = yield blob.text().then((text) => {
            return JSON.parse(text);
        });
        if (beatmap.notes == null) {
            beatmap.notes = [];
        }
        beatmap.notes.length = 0;
        // TODO: Add support for other note types/game modes
        for (const section of beatmapObj.sections) {
            for (const note of section.notes) {
                beatmap.notes.push({
                    type: note[0],
                    key: note[1],
                    startTime: note[2],
                    width: note[3],
                    endTime: note[4] ? note[4] : null,
                    duration: note[4] ? note[4] - note[2] : 0,
                    sound: note[0] === 2 ? 0 : null,
                });
            }
        }
    });
}
