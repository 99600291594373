var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth } from './react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import Supabase from './supabase';
document.addEventListener('DOMContentLoaded', () => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const supabase = Supabase.getInstance().client;
    ReactDOM.createRoot(document.getElementById('login-modal-content')).render(_jsx(React.StrictMode, { children: _jsx(Auth, { supabaseClient: supabase, appearance: { theme: ThemeSupa }, providers: ['facebook', 'google'], magicLink: true, redirectTo: window.location.origin }) }));
    const { data, error } = yield supabase.auth.getSession();
    if (error) {
        console.log('error', error);
    }
    if (data.session) {
        (_a = document.getElementById('logout-link-container')) === null || _a === void 0 ? void 0 : _a.classList.remove('hidden');
        document.querySelector('#logout-link').addEventListener('click', () => __awaiter(void 0, void 0, void 0, function* () {
            yield supabase.auth.signOut();
            window.location.reload();
        }));
        (_b = document.getElementById('login-link')) === null || _b === void 0 ? void 0 : _b.classList.add('hidden');
    }
}));
