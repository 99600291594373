export default class KDTreeNode {
    constructor() {
        this.axis = null;
        this.axisValue = null;
        this.left = null;
        this.right = null;
        this.colliders = null;
        this.layerMask = 0;
    }
}
