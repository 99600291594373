import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createStitches, createTheme } from '@stitches/core';
import { merge, VIEWS, en } from '@supabase/auth-ui-shared';
import { useEffect, useState } from 'react';
import { EmailAuth, ForgottenPassword, MagicLink, SocialAuth, UpdatePassword, } from './interfaces';
import { UserContextProvider, useUser } from './UserContext';
function Auth({ supabaseClient, socialLayout = 'vertical', providers, view = 'sign_in', redirectTo, onlyThirdPartyProviders = false, magicLink = false, showLinks = true, appearance, theme = 'default', localization = { variables: {} }, }) {
    /**
     * Localization support
     */
    var _a;
    const i18n = merge(en, (_a = localization.variables) !== null && _a !== void 0 ? _a : {});
    const [authView, setAuthView] = useState(view);
    const [defaultEmail, setDefaultEmail] = useState('');
    const [defaultPassword, setDefaultPassword] = useState('');
    /**
     * Simple boolean to detect if authView 'sign_in' or 'sign_up' is used
     *
     * @returns boolean
     */
    const SignView = authView === 'sign_in' || authView === 'sign_up';
    useEffect(() => {
        var _a, _b, _c, _d;
        createStitches({
            theme: merge((_b = (_a = appearance === null || appearance === void 0 ? void 0 : appearance.theme) === null || _a === void 0 ? void 0 : _a.default) !== null && _b !== void 0 ? _b : {}, (_d = (_c = appearance === null || appearance === void 0 ? void 0 : appearance.variables) === null || _c === void 0 ? void 0 : _c.default) !== null && _d !== void 0 ? _d : {}),
        });
    }, [appearance]);
    /**
     * Wraps around all auth components
     * renders the social auth providers if SignView is true
     *
     * also handles the theme override
     *
     * @param children
     * @returns React.ReactNode
     */
    const Container = ({ children }) => {
        var _a, _b;
        return (
        // @ts-ignore
        _jsxs("div", Object.assign({ className: theme !== 'default'
                ? createTheme(merge(
                // @ts-ignore
                appearance === null || appearance === void 0 ? void 0 : appearance.theme[theme], (_b = (_a = appearance === null || appearance === void 0 ? void 0 : appearance.variables) === null || _a === void 0 ? void 0 : _a[theme]) !== null && _b !== void 0 ? _b : {}))
                : '' }, { children: [SignView && (_jsx(SocialAuth, { appearance: appearance, supabaseClient: supabaseClient, providers: providers, socialLayout: socialLayout, redirectTo: redirectTo, onlyThirdPartyProviders: onlyThirdPartyProviders, i18n: i18n, view: authView })), !onlyThirdPartyProviders && children] })));
    };
    useEffect(() => {
        /**
         * Overrides the authview if it is changed externally
         */
        const { data: authListener } = supabaseClient.auth.onAuthStateChange((event) => {
            if (event === 'PASSWORD_RECOVERY') {
                setAuthView('update_password');
            }
            else if (event === 'USER_UPDATED') {
                setAuthView('sign_in');
            }
        });
        setAuthView(view);
        return () => authListener.subscription.unsubscribe();
    }, [view]);
    const emailProp = {
        supabaseClient,
        setAuthView,
        defaultEmail,
        defaultPassword,
        setDefaultEmail,
        setDefaultPassword,
        redirectTo,
        magicLink,
        showLinks,
        i18n,
        appearance,
    };
    /**
     * View handler, displays the correct Auth view
     * all views are wrapped in <Container/>
     */
    switch (authView) {
        case VIEWS.SIGN_IN:
            return (_jsx(Container, { children: _jsx(EmailAuth, Object.assign({}, emailProp, { authView: 'sign_in' })) }));
        case VIEWS.SIGN_UP:
            return (_jsx(Container, { children: _jsx(EmailAuth, { appearance: appearance, supabaseClient: supabaseClient, authView: 'sign_up', setAuthView: setAuthView, defaultEmail: defaultEmail, defaultPassword: defaultPassword, setDefaultEmail: setDefaultEmail, setDefaultPassword: setDefaultPassword, redirectTo: redirectTo, magicLink: magicLink, showLinks: showLinks, i18n: i18n }) }));
        case VIEWS.FORGOTTEN_PASSWORD:
            return (_jsx(Container, { children: _jsx(ForgottenPassword, { appearance: appearance, supabaseClient: supabaseClient, setAuthView: setAuthView, redirectTo: redirectTo, showLinks: showLinks, i18n: i18n }) }));
        case VIEWS.MAGIC_LINK:
            return (_jsx(Container, { children: _jsx(MagicLink, { appearance: appearance, supabaseClient: supabaseClient, setAuthView: setAuthView, redirectTo: redirectTo, showLinks: showLinks, i18n: i18n }) }));
        case VIEWS.UPDATE_PASSWORD:
            return (_jsx(Container, { children: _jsx(UpdatePassword, { appearance: appearance, supabaseClient: supabaseClient, i18n: i18n }) }));
        default:
            return null;
    }
}
// @ts-ignore
Auth.ForgottenPassword = ForgottenPassword;
// @ts-ignore
Auth.UpdatePassword = UpdatePassword;
// @ts-ignore
Auth.MagicLink = MagicLink;
// @ts-ignore
Auth.UserContextProvider = UserContextProvider;
// @ts-ignore
Auth.useUser = useUser;
export default Auth;
