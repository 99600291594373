var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import MicroModal from "micromodal";
AFRAME.registerSystem("ui", {
    schema: {},
    init: function () {
        // Help button and upload button
        this.el.addEventListener("loaded", () => {
            const uiButtons = document.querySelector(".a-enter-vr");
            const helpButton = document.createElement("button");
            helpButton.classList.add("a-enter-vr-button", "help-button");
            helpButton.setAttribute("style", "background-image: url(/images/help_icon.svg);");
            helpButton.setAttribute("data-micromodal-trigger", "help-modal");
            helpButton.setAttribute("title", "How To Play");
            uiButtons.appendChild(helpButton);
            const loadFileButton = document.createElement("button");
            loadFileButton.classList.add("a-enter-vr-button", "load-file-button");
            loadFileButton.setAttribute("style", "background-image: url(/images/file_upload_icon.svg);");
            loadFileButton.setAttribute("data-micromodal-trigger", "load-file-modal");
            loadFileButton.setAttribute("title", "Load Beatmap");
            uiButtons.appendChild(loadFileButton);
            // Handle custom beatmap loading
            this.saveImportedBeatmapCheck = document.getElementById("save-imported-check");
            const menu = document.getElementById("menu");
            document
                .getElementById("beatmap-input")
                .addEventListener("change", () => __awaiter(this, void 0, void 0, function* () {
                for (const file of document.getElementById("beatmap-input").files) {
                    if (file.name === "beatmaps.txt") {
                        file.text().then((text) => __awaiter(this, void 0, void 0, function* () {
                            const urls = text.split(/\r?\n/);
                            for (const url of urls) {
                                yield menu.components.menu.loadBeatmapFromUrl(url, this.saveImportedBeatmapCheck.checked);
                            }
                        }));
                    }
                    else {
                        yield file.arrayBuffer().then((buffer) => {
                            menu.components.menu.loadBeatmapFromArrayBuffer(buffer, this.saveImportedBeatmapCheck.checked);
                        });
                    }
                }
            }));
            document
                .getElementById("beatmap-folder-import-button")
                .addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
                var e_1, _a;
                const directoryHandle = yield window.showDirectoryPicker();
                try {
                    for (var _b = __asyncValues(directoryHandle.values()), _c; _c = yield _b.next(), !_c.done;) {
                        const entry = _c.value;
                        if (entry.kind === "file" && entry.name.endsWith(".osz")) {
                            const file = yield entry.getFile();
                            const buffer = yield file.arrayBuffer();
                            yield menu.components.menu.loadBeatmapFromArrayBuffer(buffer, this.saveImportedBeatmapCheck.checked);
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) yield _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }));
            document
                .getElementById("url-input-button")
                .addEventListener("click", () => {
                const url = document.getElementById("url-input").value;
                menu.components.menu.loadBeatmapFromUrl(url, this.saveImportedBeatmapCheck.checked);
            });
            MicroModal.init();
        });
    },
});
