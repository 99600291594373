/**
 * Basic timer that uses an audio context's time (seconds).
 * Note: for high precision timing, the webpage using it should be cross origin isolated https://web.dev/coop-coep/
 */
export default class Timer {
    constructor(audio) {
        this.audio = audio;
        this.offset = 0;
        this.lastStartTime = 0;
    }
    start(scheduledStartTime) {
        this.lastStartTime = scheduledStartTime;
    }
    pause() {
        this.offset += this.audio.currentTime - this.lastStartTime;
    }
    resume(scheduledResumeTime) {
        this.lastStartTime = scheduledResumeTime;
    }
    reset() {
        this.offset = 0;
    }
    getCurrentTime() {
        return (this.audio.currentTime - this.lastStartTime + this.offset) * 1000;
    }
    getCurrentTimeSeconds() {
        return this.audio.currentTime - this.lastStartTime + this.offset;
    }
}
