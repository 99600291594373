var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Group } from "three";
import ArrayPooledNoteManager from "../ArrayPooledNoteManager";
/**
 * Simple note manager that just spawns notes and moves them forwards
 */
export default class SimpleNoteManager extends ArrayPooledNoteManager {
    constructor(pool, spawnPoint, moveSpeed, moveDirection) {
        super(pool);
        this.moveSpeed = moveSpeed;
        this.moveDirection = moveDirection;
        this.spawnPoint = spawnPoint;
    }
    init(parent) {
        const container = new Group();
        parent.add(container);
        for (const instance of this.pool) {
            container.add(instance);
        }
        container.lookAt(this.moveDirection);
    }
    updateInstance(instance, deltaTime) {
        instance.position.z += deltaTime * this.moveSpeed;
    }
    releaseInstanceToPool(instance) {
        super.releaseInstanceToPool(instance);
        instance.visible = false;
    }
    spawnInstance(note, instance, spawnOffsetTime) {
        instance.position.set(this.spawnPoint.x, this.spawnPoint.y, this.spawnPoint.z + spawnOffsetTime * this.moveSpeed);
        instance.visible = true;
    }
    load(beatmap) {
        return __awaiter(this, void 0, void 0, function* () {
            //TODO performance optimizations
        });
    }
}
