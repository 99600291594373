var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Midi } from "@tonejs/midi";
import { BEATMAPTYPE_MIDI_KARAOKE, BEATMAPTYPE_MIDI_MANIA, BEATMAPTYPE_MIDI_SOLO, } from "../BeatmapTypes";
export function deserializeMidiBeatmap(blob, beatmap) {
    return __awaiter(this, void 0, void 0, function* () {
        if (beatmap.notes == null) {
            beatmap.notes = [];
        }
        beatmap.notes.length = 0;
        const arrayBuffer = yield blob.arrayBuffer();
        const midi = new Midi(arrayBuffer);
        const tracks = midi.tracks;
        const track = tracks.find((t) => t.channel === parseInt(beatmap.id));
        const notes = track.notes;
        const noteCount = notes.length;
        for (let i = 0; i < noteCount; i++) {
            const note = notes[i];
            beatmap.notes.push({
                type: note.midi - 60,
                startTime: note.time * 1000,
                endTime: note.time * 1000 + note.duration * 1000,
                duration: note.duration * 1000,
            });
        }
    });
}
export function deserializeMidiBeatmapSet(blob) {
    return __awaiter(this, void 0, void 0, function* () {
        const midi = new Midi(yield blob.arrayBuffer());
        const midiUrl = URL.createObjectURL(blob);
        const beatmapSet = {
            id: null,
            info: {
                song: midi.header.name,
                artist: "",
                creator: "",
                imageSrc: "",
                audioSrc: midiUrl,
                type: "",
                language: "",
                genre: "",
                tags: [],
                srcFormat: "midi",
                src: midiUrl,
            },
            beatmaps: [],
        };
        const instrumentCounts = new Map();
        for (const track of midi.tracks) {
            let isSoloTrack = true;
            let prevEndTime = -1;
            for (const note of track.notes) {
                if (prevEndTime > note.time) {
                    isSoloTrack = false;
                    break;
                }
                prevEndTime = note.time + note.duration;
            }
            const instrument = track.instrument.name;
            let instrumentCount = instrumentCounts.get(instrument);
            if (instrumentCount == null) {
                instrumentCount = 0;
            }
            instrumentCounts.set(instrument, instrumentCount + 1);
            const beatmap = {
                id: track.channel + "",
                info: {
                    name: track.name && track.name !== ""
                        ? track.name
                        : instrument + " " + instrumentCount,
                    type: "" + (isSoloTrack ? BEATMAPTYPE_MIDI_SOLO : BEATMAPTYPE_MIDI_MANIA),
                },
                notes: null,
                set: beatmapSet,
            };
            beatmapSet.beatmaps.push(beatmap);
        }
        const hasLyrics = midi.header.meta.find((meta) => meta.type === "lyrics");
        const hasSoloTrack = beatmapSet.beatmaps.find((beatmap) => beatmap.info.type === "" + BEATMAPTYPE_MIDI_SOLO);
        beatmapSet.info.type =
            "" +
                (hasSoloTrack
                    ? hasLyrics
                        ? BEATMAPTYPE_MIDI_KARAOKE
                        : BEATMAPTYPE_MIDI_SOLO
                    : BEATMAPTYPE_MIDI_MANIA);
        return beatmapSet;
    });
}
