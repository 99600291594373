var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AnimationMixer, BackSide, DataTexture, FrontSide, LuminanceFormat, RedFormat, ShaderMaterial, } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { GameStatus } from "../../game/GameState";
import BaseGraphicsManager from "../BaseGraphicsManager";
export default class CadenzaGraphicsManager extends BaseGraphicsManager {
    constructor(noteManagers, audioDataSource, activeNoteManagerIndex = 0, timeWindow = 3000) {
        super(noteManagers[activeNoteManagerIndex], timeWindow);
        this.allNoteManagers = noteManagers;
        this.audioDataSource = audioDataSource;
        this.animationMixers = [];
        this.audioMaterials = [];
        this.loadedModels = [];
    }
    init(scene, skysphere, settingsManager) {
        this.scene = scene;
        this.skysphere = skysphere;
        this.gltfLoader = new GLTFLoader();
        for (const noteManager of this.allNoteManagers) {
            noteManager.init(scene.object3D);
            noteManager.updateHeight(0);
        }
        settingsManager.addObserver("keyboardHeightOffset", (value) => {
            for (const noteManager of this.allNoteManagers) {
                noteManager.updateHeight(value);
            }
        });
    }
    onReturnToMenu() {
        super.onReturnToMenu();
        for (const mixer of this.animationMixers) {
            mixer.stopAllAction();
        }
        this.animationMixers.length = 0;
        if (this.defaultSkysphereMaterial) {
            this.skysphere.object3D.children[0].material =
                this.defaultSkysphereMaterial;
            this.defaultSkysphereMaterial = null;
            this.customSkysphereMaterial.dispose();
        }
        for (const model of this.loadedModels) {
            this.scene.object3D.remove(model);
        }
    }
    loadBeatmap(beatmap) {
        const _super = Object.create(null, {
            loadBeatmap: { get: () => super.loadBeatmap }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.loadBeatmap.call(this, beatmap);
            if (beatmap.skysphere) {
                yield this.loadSkysphere(beatmap.skysphere);
            }
            if (beatmap.models) {
                yield this.loadModels(beatmap.models);
            }
        });
    }
    loadSkysphere(skysphere) {
        return __awaiter(this, void 0, void 0, function* () {
            if (skysphere.fragmentShader && skysphere.vertexShader) {
                const fragmentShader = yield fetch(skysphere.fragmentShader).then((res) => res.text());
                const vertexShader = yield fetch(skysphere.vertexShader).then((res) => res.text());
                const material = new ShaderMaterial({
                    fragmentShader: fragmentShader,
                    vertexShader: vertexShader,
                    side: BackSide,
                });
                this.customSkysphereMaterial = material;
                this.defaultSkysphereMaterial = (this.skysphere.object3D.children[0]).material;
                this.skysphere.object3D.children[0].material = material;
            }
        });
    }
    loadModels(models) {
        return __awaiter(this, void 0, void 0, function* () {
            for (const model of models) {
                yield new Promise((resolve, reject) => {
                    this.gltfLoader.load(model.file, (gltf) => __awaiter(this, void 0, void 0, function* () {
                        if (model.vertexShader && model.fragmentShader) {
                            const fragmentShader = yield fetch(model.fragmentShader).then((res) => res.text());
                            const vertexShader = yield fetch(model.vertexShader).then((res) => res.text());
                            let uniforms;
                            if (this.audioDataSource.audioData) {
                                const format = this.scene.renderer.capabilities.isWebGL2
                                    ? RedFormat
                                    : LuminanceFormat;
                                uniforms = {
                                    time: { value: 0 },
                                    audioData: {
                                        value: new DataTexture(this.audioDataSource.audioData, 1024, 1, format),
                                    },
                                };
                            }
                            else {
                                uniforms = {
                                    time: { value: 0 },
                                };
                            }
                            const material = new ShaderMaterial({
                                uniforms: uniforms,
                                fragmentShader: fragmentShader,
                                vertexShader: vertexShader,
                                side: FrontSide,
                            });
                            this.audioMaterials.push(material);
                            gltf.scene.children[0].material = material;
                        }
                        this.scene.object3D.add(gltf.scene);
                        this.loadedModels.push(gltf.scene);
                        const positionVector = model.position;
                        if (positionVector) {
                            gltf.scene.position.set(positionVector[0], positionVector[1], positionVector[2]);
                        }
                        if (gltf.animations.length > 0) {
                            const mixer = new AnimationMixer(gltf.scene);
                            mixer.clipAction(gltf.animations[0]).play();
                            this.animationMixers.push(mixer);
                        }
                        resolve();
                    }));
                });
            }
        });
    }
    update(gamestate, deltaTime) {
        super.update(gamestate, deltaTime);
        if (gamestate.status === GameStatus.PLAYING) {
            const deltaSeconds = deltaTime / 1000;
            // Update animation mixers
            for (const animationMixer of this.animationMixers) {
                animationMixer.update(deltaSeconds);
            }
            for (const audioMaterial of this.audioMaterials) {
                audioMaterial.uniforms.audioData.value.needsUpdate = true;
                audioMaterial.uniforms.time.value =
                    audioMaterial.uniforms.time.value + deltaSeconds;
            }
        }
    }
}
