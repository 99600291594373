var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { css } from '@stitches/core';
import { generateClassNames } from '@supabase/auth-ui-shared';
const inputDefaultStyles = css({
    fontFamily: '$inputFontFamily',
    background: '$inputBackground',
    borderRadius: '$inputBorderRadius',
    padding: '$inputPadding',
    cursor: 'text',
    borderWidth: '$inputBorderWidth',
    borderColor: '$inputBorder',
    borderStyle: 'solid',
    fontSize: '$baseInputSize',
    width: '100%',
    color: '$inputText',
    boxSizing: 'border-box',
    '&:hover': {
        borderColor: '$inputBorderHover',
        outline: 'none',
    },
    '&:focus': {
        borderColor: '$inputBorderFocus',
        outline: 'none',
    },
    '&::placeholder': {
        color: '$inputPlaceholder',
        letterSpacing: 'initial',
    },
    transitionPproperty: 'background-color, border',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '100ms',
    variants: {
        type: {
            default: {
                letterSpacing: '0px',
            },
            password: {
                letterSpacing: '6px',
            },
        },
    },
});
const Input = (_a) => {
    var _b;
    var { children, appearance } = _a, props = __rest(_a, ["children", "appearance"]);
    const classNames = generateClassNames('input', inputDefaultStyles({
        type: props.type === 'password' ? 'password' : 'default',
    }), appearance);
    return (_jsx("input", Object.assign({}, props, { style: (_b = appearance === null || appearance === void 0 ? void 0 : appearance.style) === null || _b === void 0 ? void 0 : _b.input, className: classNames.join(' ') }, { children: children })));
};
export { Input };
