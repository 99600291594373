import { GAMEMODE_TONO } from "./GameModes";
import { GameStatus } from "./GameState";
const JUDGEMENT_THRESHOLD = 500;
export const TONE_VOLUME_INPUT = "toneVolume";
export const TONE_PITCH_INPUT = "tonePitch";
const SCOREBIT = 0b00000001;
export default class TonoGameState {
    constructor() {
        this.events = [];
        this.listeners = new Map();
        this.noteQueue = []; //head of queue is at the end of the array
        this.eventPool = [
            { note: null, judgement: null },
            { note: null, judgement: null },
            { note: null, judgement: null },
            { note: null, judgement: null },
            { note: null, judgement: null },
            { note: null, judgement: null },
            { note: null, judgement: null },
            { note: null, judgement: null },
        ];
        this.shiftCounts = [0, 0, 0, 0, 0, 0, 0, 0];
        this.status = GameStatus.MENU;
        this.beatmap = null;
        this.currentSongTime = 0;
        this.timingOffset = 50;
        this.score = {
            beatmap: null,
            gameMode: GAMEMODE_TONO,
            score: 0,
            highScore: 0,
            combo: 0,
            maxCombo: 0,
            data: [],
        };
    }
    getGameMode() {
        return GAMEMODE_TONO;
    }
    addChangeListener(property, handler) {
        if (!this.listeners.has(property)) {
            this.listeners.set(property, []);
        }
        this.listeners.get(property).push(handler);
    }
    loadBeatmap(beatmap) {
        this.beatmap = beatmap;
        // assume beatmap notes are sorted by time in ascending order
        for (let i = this.beatmap.notes.length - 1; i > -1; i--) {
            this.noteQueue.push(this.beatmap.notes[i]);
        }
    }
    pushHitEvent(note, judgement, ignoreTimeDelta = false) {
        const event = this.eventPool.pop();
        event.judgement = judgement;
        event.note = note;
        event.ignoreTimeDelta = ignoreTimeDelta;
        this.events.push(event);
    }
    judgeHit(note, inputPitch) {
        // TODO finalize inputPitch value
        note.pitchDiff = Math.abs((inputPitch - note.type) % 12);
        note.pitchDiff = Math.min(note.pitchDiff, 12 - note.pitchDiff);
        note.absPitchDiff = Math.abs(note.pitchDiff);
        note.totalError = note.absPitchDiff * 10 + note.absTimeDelta;
        if (note.totalError > 120 /* ErrorThresholds.GOOD */) {
            if (note.totalError < 180 /* ErrorThresholds.BAD */) {
                return 0 /* Judgement.MISS */;
            }
            else {
                return 1 /* Judgement.BAD */;
            }
        }
        else {
            if (note.totalError <= 45 /* ErrorThresholds.EXCELLENT */) {
                return 7 /* Judgement.EXCELLENT */;
            }
            else {
                return 3 /* Judgement.GOOD */;
            }
        }
    }
    updateTimeDelta(note) {
        if (note.isActive) {
            note.timeDelta = this.currentSongTime - note.endTime - this.timingOffset;
        }
        else {
            note.timeDelta =
                this.currentSongTime - note.startTime - this.timingOffset;
        }
    }
    reset() {
        this.score.score = 0;
        this.score.combo = 0;
        this.score.accuracy = 0;
        this.score.data.length = 0;
        this.score.judgementCounts = {};
        this.shiftCounts.fill(0);
        this.noteQueue.length = 0;
        while (this.events.length > 0) {
            this.eventPool.push(this.events.pop());
        }
        for (const note of this.beatmap.notes) {
            note.isActive = false;
            delete note.pitchDiff;
        }
    }
    setStatus(status) {
        this.status = status;
        for (const handler of this.listeners.get("status")) {
            handler(status);
        }
    }
    updateScore() {
        if (this.events.length > 0) {
            for (const event of this.events) {
                if (event.judgement === 0 /* Judgement.MISS */) {
                    this.score.combo = 0;
                    this.score.data.push(181);
                }
                else {
                    if (event.judgement & SCOREBIT) {
                        this.score.combo += 1;
                        if (this.score.combo > this.score.maxCombo) {
                            this.score.maxCombo = this.score.combo;
                        }
                        switch (event.judgement) {
                            case 1 /* Judgement.BAD */:
                                this.score.score += 1;
                                break;
                            case 3 /* Judgement.GOOD */:
                                this.score.score += 2;
                                break;
                            case 7 /* Judgement.EXCELLENT */:
                                this.score.score += 6;
                                break;
                        }
                        if (this.score.score > this.score.highScore) {
                            this.score.highScore = this.score.score;
                        }
                    }
                    this.score.data.push(event.note.totalError);
                }
            }
            for (const handler of this.listeners.get("score")) {
                handler(this.score);
            }
        }
    }
    update(newSongTime, inputs) {
        this.currentSongTime = newSongTime;
        // Latency sources:
        // 1. Song audio latency: time between song time and actual time user hears sound
        // 2. Input feedback latency: time between user input and when user feels haptic/hears audio
        // If input feedback latency is greater than song audio latency. User should trigger the input earlier to compensate.
        if (this.status === GameStatus.PLAYING) {
            while (this.events.length > 0) {
                this.eventPool.push(this.events.pop());
            }
            // handle notes at front of each queue
            let note = this.noteQueue[this.noteQueue.length - 1];
            if (note) {
                this.updateTimeDelta(note);
                if (note.timeDelta > 180 /* ErrorThresholds.BAD */) {
                    this.pushHitEvent(note, 0 /* Judgement.MISS */);
                    this.noteQueue.pop();
                    note = this.noteQueue[this.noteQueue.length - 1];
                    if (note) {
                        this.updateTimeDelta(note);
                    }
                }
            }
            let tone;
            const toneActive = inputs.eventMap.get(TONE_VOLUME_INPUT);
            if (toneActive) {
                tone = inputs.stateMap.get(TONE_PITCH_INPUT).inputs[0].value;
            }
            if (tone && note) {
                note.absTimeDelta = Math.abs(note.timeDelta);
                if (!note.isActive && note.absTimeDelta <= JUDGEMENT_THRESHOLD) {
                    this.pushHitEvent(note, this.judgeHit(note, tone));
                    note.isActive = true;
                }
            }
            if (note && note.isActive) {
                this.updateTimeDelta(note);
                if (note.timeDelta >= 0 || toneActive === 0) {
                    this.pushHitEvent(note, 2 /* Judgement.PASS */);
                    note.isActive = false;
                    this.noteQueue.pop();
                }
            }
        }
    }
}
