var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { css } from '@stitches/core';
import { generateClassNames } from '@supabase/auth-ui-shared';
const anchorHTMLAttributes = css({
    fontFamily: '$bodyFontFamily',
    fontSize: '$baseBodySize',
    marginBottom: '$anchorBottomMargin',
    color: '$anchorTextColor',
    display: 'block',
    textAlign: 'center',
    textDecoration: 'underline',
    '&:hover': {
        color: '$anchorTextHoverColor',
    },
});
const Anchor = (_a) => {
    var _b;
    var { children, appearance } = _a, props = __rest(_a, ["children", "appearance"]);
    const classNames = generateClassNames('anchor', anchorHTMLAttributes(), appearance);
    return (_jsx("a", Object.assign({}, props, { style: (_b = appearance === null || appearance === void 0 ? void 0 : appearance.style) === null || _b === void 0 ? void 0 : _b.anchor, className: classNames.join(' ') }, { children: children })));
};
export { Anchor };
