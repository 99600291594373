var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Vector3 } from "three";
import InstancedMeshNoteManager from "./InstancedMeshNotesManager";
const dummyVector = new Vector3();
/**
 * Simple note manager that just spawns notes and moves them forwards
 */
export default class InstancedSimpleNoteManager extends InstancedMeshNoteManager {
    constructor(pool, spawnPoint, moveSpeed, moveDirection) {
        super(pool);
        this.moveSpeed = moveSpeed;
        this.moveDirection = moveDirection;
        this.spawnPoint = spawnPoint;
    }
    init(parent) {
        this.pool.mesh.lookAt(this.moveDirection);
        parent.add(this.pool.mesh);
    }
    getOffsetSpawnPosition(note, spawnOffsetTime) {
        dummyVector.copy(this.spawnPoint);
        dummyVector.z += spawnOffsetTime * this.moveSpeed;
        return dummyVector;
    }
    updateInstance(instance, deltaTime) {
        this.pool.incrementInstancePositionZ(instance, deltaTime * this.moveSpeed);
    }
    load(beatmap) {
        return __awaiter(this, void 0, void 0, function* () {
            //TODO performance optimizations
        });
    }
}
