var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// eslint-disable-next-line @typescript-eslint/no-var-requires
const OsuParser = require("osu-parser-web");
export function deserializeOsuBeatmap(blob, beatmap) {
    return __awaiter(this, void 0, void 0, function* () {
        if (beatmap.notes == null) {
            beatmap.notes = [];
        }
        beatmap.notes.length = 0;
        const osuBeatmap = OsuParser.parseContent(yield blob.text());
        osuBeatmap.hitObjects.forEach((hitObject) => {
            const note = {
                type: null,
                startTime: null,
            };
            note.startTime = hitObject.startTime;
            if (hitObject.endTime) {
                note.endTime = hitObject.endTime;
                note.duration = hitObject.endTime - hitObject.startTime;
            }
            else {
                note.duration = 0;
            }
            if (osuBeatmap.Mode == 1) {
                // Taiko
                if (hitObject.objectName === "slider" ||
                    hitObject.objectName === "spinner") {
                    note.type = 0b0100; // drumroll
                    // TODO shaker
                }
                else {
                    if (hitObject.soundTypes.indexOf("whistle") != -1 ||
                        hitObject.soundTypes.indexOf("clap") != -1) {
                        // kat
                        note.type = 0b0001;
                    }
                    else {
                        // don
                        note.type = 0b0000;
                    }
                }
                if (hitObject.soundTypes.indexOf("finish") != -1) {
                    note.type |= 0b0010; // large note
                }
            }
            else if (osuBeatmap.Mode == 3) {
                // Mania
                if (hitObject.objectName == "hold") {
                    note.type = 2;
                }
                else {
                    note.type = 0;
                }
                note.key = Math.floor((hitObject.position[0] * osuBeatmap.CircleSize) / 512);
                note.width = 1;
            }
            beatmap.notes.push(note);
        });
    });
}
