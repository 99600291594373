var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getRank } from "../scoring/models/Score";
const CHAR_LENGTHS = {
    rank: 3,
    score: 10,
    acc: 7,
    grade: 2,
};
AFRAME.registerComponent("leaderboard", {
    init: function () {
        this.scores = new Map();
        this.lastSyncDateTimes = new Map();
    },
    updateLeaderboard: function (beatmapHash, gameMode) {
        return __awaiter(this, void 0, void 0, function* () {
            const scores = yield this.getScores(beatmapHash, gameMode);
            // todo only keep track of one score per user
            this.el.setAttribute("value", scores
                .map((score, index) => {
                const rank = index + 1 + ".";
                const scoreStr = score.score.toString();
                const acc = (score.acc / 100).toFixed(2) + "%";
                const grade = score.ss ? "SS" : getRank(score.acc / 100, {});
                return `${rank.padEnd(CHAR_LENGTHS.rank, " ")} ${scoreStr.padEnd(CHAR_LENGTHS.score, " ")} ${acc.padStart(CHAR_LENGTHS.acc, " ")} ${grade.padStart(CHAR_LENGTHS.grade, " ")}`;
            })
                .join("\n"));
        });
    },
    getScores: function (beatmapHash, gameMode) {
        return __awaiter(this, void 0, void 0, function* () {
            const key = gameMode + beatmapHash;
            const lastSyncTime = this.lastSyncDateTimes.get(key) || new Date(0);
            const scores = this.scores.get(key) || [];
            const tenthScore = scores[9] || { score: 0 };
            const queryTime = new Date();
            let sortedScores = [];
            yield fetch(`https://scores.cadenzavr.com/${beatmapHash}/${gameMode}?since=${lastSyncTime.toISOString()}&minScore=${tenthScore.score}`)
                .then((res) => res.json())
                .then((data) => {
                sortedScores = scores
                    .concat(data)
                    .sort((a, b) => {
                    if (a.score === b.score) {
                        if (a.ss === b.ss) {
                            return b.acc - a.acc;
                        }
                        return a.ss ? -1 : 1;
                    }
                    return b.score - a.score;
                })
                    .slice(0, 10);
                this.scores.set(key, sortedScores);
                this.lastSyncDateTimes.set(key, queryTime);
            })
                .catch((err) => {
                console.error(err);
            });
            return sortedScores;
        });
    },
});
