export function taikoToClassic(beatmap) {
    const newNotes = [];
    let prevKat = null;
    for (const note of beatmap.notes) {
        if (note.type === 4 /* TaikoNoteTypes.SMALLDRUMROLL */ ||
            note.type === 6 /* TaikoNoteTypes.LARGEDRUMROLL */ ||
            note.type === 10 /* TaikoNoteTypes.SHAKER */) {
            newNotes.push({
                key: 3,
                width: 2,
                startTime: note.startTime,
                endTime: note.endTime,
                duration: note.endTime - note.startTime,
                type: 3 /* ClassicNoteTypes.ROLL_NOTE */,
            });
        }
        else {
            const newNote = {
                type: 0 /* ClassicNoteTypes.HIT_NOTE */,
                key: null,
                width: null,
                startTime: note.startTime,
                duration: 0,
            };
            if (note.type & 0b1) {
                //kat
                newNote.key = prevKat == 5 ? 2 : 5;
                newNote.width = 1;
                prevKat = newNote.key;
            }
            else {
                //don
                newNote.width = 2;
                newNote.key = 3;
                prevKat = null;
            }
            if (note.type & 0b10) {
                //large
                const newNote2 = Object.assign({}, newNote);
                if (note.type & 0b1) {
                    //kat
                    newNote.key = 2;
                    newNote2.key = 5;
                }
                else {
                    //don
                    newNote.width = 1;
                    newNote2.key = 4;
                    newNote2.width = 1;
                }
                newNotes.push(newNote2);
            }
            newNotes.push(newNote);
        }
    }
    beatmap.notes = newNotes;
}
