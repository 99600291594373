var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { VIEWS } from '@supabase/auth-ui-shared';
import { Anchor, Button, Container, Input, Label, Message, } from './../../UI';
function ForgottenPassword({ setAuthView = () => { }, supabaseClient, redirectTo, i18n, appearance, showLinks = false, }) {
    var _a;
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const handlePasswordReset = (e) => __awaiter(this, void 0, void 0, function* () {
        var _b;
        e.preventDefault();
        setError('');
        setMessage('');
        setLoading(true);
        const { error } = yield supabaseClient.auth.resetPasswordForEmail(email, {
            redirectTo,
        });
        if (error)
            setError(error.message);
        else
            setMessage((_b = i18n === null || i18n === void 0 ? void 0 : i18n.forgotten_password) === null || _b === void 0 ? void 0 : _b.confirmation_text);
        setLoading(false);
    });
    const labels = i18n === null || i18n === void 0 ? void 0 : i18n.forgotten_password;
    return (_jsx("form", Object.assign({ id: "auth-forgot-password", onSubmit: handlePasswordReset }, { children: _jsxs(Container, Object.assign({ gap: "large", direction: "vertical", appearance: appearance }, { children: [_jsxs(Container, Object.assign({ gap: "large", direction: "vertical", appearance: appearance }, { children: [_jsxs("div", { children: [_jsx(Label, Object.assign({ htmlFor: "email", appearance: appearance }, { children: labels === null || labels === void 0 ? void 0 : labels.email_label })), _jsx(Input, { id: "email", name: "email", type: "email", autoFocus: true, placeholder: labels === null || labels === void 0 ? void 0 : labels.email_input_placeholder, onChange: (e) => setEmail(e.target.value), appearance: appearance })] }), _jsx(Button, Object.assign({ type: "submit", color: "primary", loading: loading, appearance: appearance }, { children: loading ? labels === null || labels === void 0 ? void 0 : labels.loading_button_label : labels === null || labels === void 0 ? void 0 : labels.button_label }))] })), showLinks && (_jsx(Anchor, Object.assign({ href: "#auth-sign-in", onClick: (e) => {
                        e.preventDefault();
                        setAuthView(VIEWS.SIGN_IN);
                    }, appearance: appearance }, { children: (_a = i18n === null || i18n === void 0 ? void 0 : i18n.sign_in) === null || _a === void 0 ? void 0 : _a.link_text }))), message && _jsx(Message, Object.assign({ appearance: appearance }, { children: message })), error && (_jsx(Message, Object.assign({ color: "danger", appearance: appearance }, { children: error })))] })) })));
}
export { ForgottenPassword };
