var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// eslint-disable-next-line @typescript-eslint/no-var-requires
import MidiPlayer from "./MidiPlayer";
export default class AudioManager {
    constructor() {
        this.hitSoundsEnabled = true;
        this.audioBuffers = [null]; // first elem is null so sound ids start at 1
        this.listeners = new Map();
    }
    addEventListener(event, handler) {
        if (!this.listeners.has(event)) {
            this.listeners.set(event, []);
        }
        this.listeners.get(event).push(handler);
    }
    broadcastEvent(event) {
        if (this.listeners.has(event)) {
            for (const handler of this.listeners.get(event)) {
                handler();
            }
        }
    }
    init(settingsManager) {
        this.hitSoundsEnabled = settingsManager.getSettingValue("hitSoundsEnabled");
        if (this.hitSoundsEnabled == null) {
            this.hitSoundsEnabled = true;
        }
        settingsManager.addObserver("hitSoundsEnabled", (value) => {
            this.hitSoundsEnabled = value;
            this.startContext();
        });
        this.audioElement = new Audio();
        this.audioElement.volume = 0.5;
        this.audioElement.addEventListener("ended", () => {
            this.broadcastEvent("songEnd");
        });
        if (AudioContext) {
            this.audioContext = new AudioContext();
            this.analyser = this.audioContext.createAnalyser();
            this.audioContext
                .createMediaElementSource(this.audioElement)
                .connect(this.analyser);
            this.analyser.connect(this.audioContext.destination);
            this.audioData = new Uint8Array(this.analyser.frequencyBinCount);
            this.midiPlayer = new MidiPlayer(this.audioContext);
            this.midiPlayer.addEventListener("ended", () => {
                this.broadcastEvent("songEnd");
            });
        }
        else {
            console.error("AudioContext not supported");
        }
    }
    startContext() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.audioContext.state === "suspended") {
                yield this.audioContext.resume();
            }
        });
    }
    loadMidiBeatmap(beatmap) {
        return __awaiter(this, void 0, void 0, function* () {
            const midiFile = yield fetch(beatmap.set.info.audioSrc).then((res) => res.arrayBuffer());
            yield this.midiPlayer.loadArrayBuffer(midiFile);
        });
    }
    loadBeatmap(beatmap) {
        return __awaiter(this, void 0, void 0, function* () {
            this.beatmap = beatmap;
            if (beatmap.set.info.srcFormat === "midi") {
                yield this.loadMidiBeatmap(beatmap);
                this.isMidiActive = true;
            }
            else {
                this.audioElement.src = beatmap.set.info.audioSrc;
                this.audioElement.load();
                this.isMidiActive = false;
            }
            const sounds = beatmap.set.info.sounds;
            if (sounds) {
                const soundIds = [];
                for (let i = 0; i < sounds.length; i++) {
                    const soundId = yield fetch(sounds[i])
                        .then((res) => res.arrayBuffer())
                        .then((audioData) => {
                        return this.registerSound(audioData);
                    });
                    soundIds.push(soundId);
                }
                for (const note of beatmap.notes) {
                    if (note.sound != null) {
                        note.sound = soundIds[note.sound];
                    }
                }
            }
        });
    }
    onGameStart() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.startContext();
            if (this.isMidiActive) {
                return yield this.midiPlayer.play();
            }
            else {
                yield this.audioElement.play();
            }
            return this.audioContext.currentTime;
        });
    }
    onGamePause() {
        if (this.isMidiActive) {
            this.midiPlayer.pause();
        }
        else {
            this.audioElement.pause();
        }
    }
    onGameResume() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isMidiActive) {
                return yield this.midiPlayer.resume();
            }
            yield this.audioElement.play();
            return this.audioContext.currentTime;
        });
    }
    onGameRestart() {
        return __awaiter(this, void 0, void 0, function* () {
            this.audioElement.pause();
            this.audioElement.currentTime = 0;
            if (this.isMidiActive) {
                this.midiPlayer.reset();
                yield this.midiPlayer.play();
            }
            else {
                yield this.audioElement.play();
            }
            return this.audioContext.currentTime;
        });
    }
    update(gamestate) {
        for (const hitEvent of gamestate.events) {
            if (hitEvent.note.sound &&
                !hitEvent.note.isActive &&
                hitEvent.judgement) {
                this.playHitSound(hitEvent.note.sound);
            }
        }
    }
    registerSoundFromUrl(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const audioData = yield fetch(url).then((res) => res.arrayBuffer());
            return this.registerSound(audioData);
        });
    }
    registerSound(audioData) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.audioContext.decodeAudioData(audioData, (buffer) => {
                this.audioBuffers.push(buffer);
            });
            return this.audioBuffers.length - 1;
        });
    }
    playHitSound(id) {
        if (this.hitSoundsEnabled) {
            this.playSound(id);
        }
    }
    playSound(id) {
        const buffer = this.audioBuffers[id];
        const source = this.audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(this.audioContext.destination);
        source.start();
    }
}
