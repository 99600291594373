var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { template } from '@supabase/auth-ui-shared';
import { Button, Container, Divider } from './../../UI';
import * as SocialIcons from './../Icons';
function SocialAuth({ supabaseClient, socialLayout = 'vertical', providers = ['github', 'google', 'azure'], redirectTo, onlyThirdPartyProviders = true, view = 'sign_in', i18n, appearance, }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const verticalSocialLayout = socialLayout === 'vertical' ? true : false;
    const handleProviderSignIn = (provider) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        const { error } = yield supabaseClient.auth.signInWithOAuth({
            provider,
            options: { redirectTo },
        });
        if (error)
            setError(error.message);
        setLoading(false);
    });
    function capitalize(word) {
        const lower = word.toLowerCase();
        return word.charAt(0).toUpperCase() + lower.slice(1);
    }
    return (_jsx(_Fragment, { children: providers && providers.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Container, Object.assign({ gap: "large", direction: "vertical", appearance: appearance }, { children: _jsx(Container, Object.assign({ direction: verticalSocialLayout ? 'vertical' : 'horizontal', gap: verticalSocialLayout ? 'small' : 'medium', appearance: appearance }, { children: providers.map((provider) => {
                            var _a;
                            const AuthIcon = SocialIcons[provider];
                            return (_jsx(Button, Object.assign({ color: "default", icon: AuthIcon ? _jsx(AuthIcon, {}) : '', loading: loading, onClick: () => handleProviderSignIn(provider), appearance: appearance }, { children: verticalSocialLayout &&
                                    template((_a = i18n === null || i18n === void 0 ? void 0 : i18n[view]) === null || _a === void 0 ? void 0 : _a.social_provider_text, {
                                        provider: capitalize(provider),
                                    }) }), provider));
                        }) })) })), !onlyThirdPartyProviders && _jsx(Divider, { appearance: appearance })] })) }));
}
export { SocialAuth };
